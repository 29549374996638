/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

* {
  box-sizing: border-box;
}

.spacer {
  flex: 1 1 auto;
}

li,
p {
  color: #505160;
}

p {
  font-size: 16px;
  line-height: 28px;
  position: relative;
}

img {
  width: 100%;
  height: auto;
}

section:not(:first-child) {
  margin: 32px 0;
}

@media only screen and (min-width: 600px) {
  section:not(:first-child) {
    margin: 32px 0;
  }
}
body section .mat-icon {
  color: #333;
}

.container {
  width: 100% !important;
  max-width: 800px !important;
  margin: 0 auto !important;
  padding: 1rem;
}

section a {
  color: #3f51b5;
  font-weight: 500;
  text-decoration: none;
}

.next-section {
  float: right;
  margin: 32px 0 !important;
}
.next-section .mat-icon {
  color: #fff;
}

h1 {
  text-align: center;
  font-size: 4rem;
}

h2 {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Emoji, Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
  color: #505160;
}

h3 {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Emoji, Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #505160;
  clear: both;
  margin-bottom: 0;
}

h4 {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Emoji, Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #666;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 0;
}

table.overview {
  margin: 10px auto;
  border-radius: 16px;
}
table.overview caption {
  font-size: 1.6em;
  font-weight: 400;
  padding: 10px 0;
}
table.overview thead th {
  font-weight: 400;
  background: #8a97a0;
  color: #FFF;
}
table.overview tr {
  background: #f4f7f8;
  border-bottom: 1px solid #FFF;
  margin-bottom: 5px;
}
table.overview tr:nth-child(even) {
  background: #e8eeef;
}
table.overview th, table.overview td {
  text-align: left;
  padding: 20px;
  font-weight: 300;
}

.subtitle {
  width: 100%;
  text-align: center;
  margin-top: -40px;
  color: #576a71;
}

.github-link {
  height: 30px;
  width: 30px;
}

.hero {
  height: 325px;
  object-fit: cover;
}